import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {

    function resolve(value, callback) {
        if (typeof value === 'number' && typeof callback === 'function') {
            return callback(value);
        } else {
            return value;
        }
    }

    return {
        root: props => {

            const { height, width, gap } = props;

            return {
                width: '100%',
                maxWidth: '100%',
                marginTop: gap,
                marginBottom: gap,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
        
                '& > *': {
                    height: props.landscape
                        ? resolve(height, h => h * .5)
                        : resolve(height, h => h * .6),
                    width: props.landscape
                        ? resolve(width, w => w + (gap * .5))
                        : resolve(width, w => w * .6),
                    maxWidth: '100%',
                },
        
                '& > *:not(:first-child)': {
                    marginTop: gap,
                    marginLeft: 0,
                },
        
                [theme.breakpoints.up('sm')]: {
                    '& *': {
                        height: props.landscape
                            ? resolve(height, h => h * .7)
                            : resolve(height, h => h * .8),
                        width: props.landscape
                            ? resolve(width, w => ((w*2) + gap) * .7)
                            : resolve(width, w => w * .8)
                    },
                },
        
                [theme.breakpoints.up('md')]: {
        
                    flexDirection: 'row',
        
                    '& *': {
                        height: resolve(height, h => h * .7),
                        width: resolve(width, w => (props.landscape ? (w * 2) + gap : width) * .7),
                    },
        
                    '& > *:not(:first-child)': {
                        marginTop: 0,
                        marginLeft: gap,
                    },
                },
        
                [theme.breakpoints.up('lg')]: {
                    '& *': {
                        height: height,
                        width: props.landscape
                            ? resolve(width, w => (w * 2) + gap)
                            : width
                    },
                },
                
            }
        }
    }
});