import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './row.css';

const ImageRow = (props) => {

    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
}

ImageRow.propTypes = {
    children: PropTypes.node.isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    gap: PropTypes.number,
    landscape: PropTypes.bool,
}

ImageRow.defaultProps = {
    height: 400,
    width: 300,
    gap: 0,
    landscape: false,
}

export default ImageRow;