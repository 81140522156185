import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import Img from 'gatsby-image';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ImageRow from 'components/image/row';
import theme from 'components/theme';

const themeImage = theme.components.image.photographySeries;

const PhotographySeries = (props) => {

    const { data, pageContext } = props;

    const series = _.toArray(_.groupBy(
        data.allImages.edges.slice(1).map(({ node }) => ({
            ...node,
            layoutGroup: node.name.replace(/[^0-9]/g, ''),
        })),
        "layoutGroup"
    ))

    const isLandscape = (node) => {
        return node.childImageSharp.resolutions.aspectRatio > 1;
    }

    const renderImageGroup = (group, i) => {

        let width = themeImage.width;
        let height = themeImage.height;

        let landscape = group.length <= 1 && isLandscape(group[0]);
        let blockLayout = group.length === 1 && pageContext.frontmatter.layoutType === "block";

        // block layout will preserve aspect ratio of original image and make modifications to the dimensions
        if (blockLayout) {
            // block layout scaling degree
            let scaleUp = landscape ? 1.5 : 1.25;
            width *= scaleUp;
            height *= scaleUp;
            // original aspect ratio of image
            let aspectRatio = group[0].childImageSharp.resolutions.aspectRatio;

            if (landscape) {
                height = width / aspectRatio;
            } else {
                width = height * aspectRatio;
            }
        }

        return (
            <ImageRow
                key={i}
                height={height}
                width={width}
                gap={themeImage.gap * (blockLayout ? 2 : 1)}
                landscape={blockLayout ? false : landscape}
            >
                {group.map((node) => {
                    return (
                        <Img 
                            key={node.id} 
                            fluid={node.childImageSharp.fluid} 
                            alt={`${pageContext.frontmatter.title}-${node.name}`}
                        />
                    );
                })}
            </ImageRow>
        );

    }

    const DisplayText = ({ children }) => (
        <Typography variant="h4" component="h1" style={{ fontFamily: pageContext.frontmatter.titleFont }}>
            {children}
        </Typography>
    )

    const titleImageNode = data.allImages.edges[0].node;

    return (
        <Layout containerSize="lg">
            <PageTitle navOffset>
                <ImageRow
                    height={themeImage.height}
                    width={themeImage.width}
                    gap={themeImage.gap}
                    landscape={isLandscape(titleImageNode)}
                >
                    <Img 
                        key={titleImageNode.id} 
                        fluid={titleImageNode.childImageSharp.fluid} 
                        alt={`${pageContext.frontmatter.title}-${titleImageNode.name}`}
                    />
                </ImageRow>
            </PageTitle>
            <Box mb={8} textAlign="center">
                <DisplayText>{pageContext.frontmatter.title}</DisplayText>
                <ImageRow
                    height='auto'
                    width={themeImage.width}
                    landscape={true}
                >
                    <Box
                        component="p"
                        textAlign="left"
                        py={4}
                        textAlign={pageContext.internal.content.length < 100 ? "center" : "left"}
                    >
                        {pageContext.internal.content}
                    </Box>
                </ImageRow>
            </Box>
            <Box m7={8}>
                {series.map(renderImageGroup)}
            </Box>
            {pageContext.frontmatter.endText && (
                <Box display="block" pt={6} pb={24} textAlign="center">
                    <DisplayText>End</DisplayText>
                </Box>
            )}
        </Layout>
    );
}

// $assetsPath is provided by the page context in gatsby-node.js
export const query = graphql`
    query photographySeriesImages($assetsPath: String) {
        allImages: allFile(
            filter: {relativeDirectory: { eq: $assetsPath }},
            sort: {fields: name, order: ASC}
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        resolutions {
                            aspectRatio,
                            width,
                            height
                        }
                        fluid(maxHeight: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default PhotographySeries;